










import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
@Component({
  components: {
    Header,
    Footer,
  },
})
export default class Home extends Vue {
  searchShowFooter = false;
  get isHome(): boolean {
    return this.$route.name === "Home";
  }
  get isSearch(): boolean {
    return this.$route.name === "Search";
  }
  // get showFooter():boolean{
  //   if(this.$route.name === "Home"){
  //     return false;
  //   }else if(this.$route.name === "Search"){
  //     return this.searchShowFooter;
  //   }else{
  //     return true;
  //   }
  // }
 
}


