





























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Category from '@/components/Category.vue'
import $ from "jquery";


import { getUserInfo, hxwLogout, listPartNumberTips } from "@/api";
import { config } from 'node_modules/vue/types/umd';
@Component({
  components: {
    Category,
    
  },
})
export default class Header extends Vue {
  keyword = '';
  routeKeyword = '';
  tipArr  = [] ;
  get isHome(): boolean {
    return this.$route.name === "Home";
  }

  @Watch('$route.name')
  onIdChanged(): void {
    // if (this.$route.name !== 'Search') {
    //   this.keyword = ''
    //   this.routeKeyword = ''
    // }
    if (this.$route.name == 'Search'){
        this.keyword = this.$route.query.keyword.toString().trim().replace(/[\u4e00-\u9fa5]/ig,'') 
    }

  }
  isLogin = window.localStorage.getItem('Authorization');
  userInfo = JSON.stringify(window.localStorage.getItem('userInfo'));
  salerQq ="";
  searchKey = "";
  tipsShowVisible = false;
  timer= null;
  isSearch =null
  aaa= false;
  clickOutside = {
  // 初始化指令
  bind(el :any, binding :any, vnode :any) {
    function clickHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = clickHandler;
    document.addEventListener('click', clickHandler);
  },
  // update() {},
  unbind(el:any, binding:any) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
};
directives: {clickOutside}
  // userInfo = {
  //     admin: false,
  //     avatar: "",
  //     companyId: 0,
  //     companyName: null,
  //     companyType: 2,
  //     createBy: "",
  //     createTime: "",
  //     delFlag: "0",
  //     dept: null,
  //     deptId: null,
  //     email: "",
  //     isVip: 0,
  //     loginDate: null,
  //     loginIp: "",
  //     nickName: "",
  //     orderField: null,
  //     orderSort: null,
  //     params: {},
  //     password: "",
  //     phonenumber: "",
  //     postIds: null,
  //     remark: null,
  //     roleIds: null,
  //     roles: [],
  //     salt: null,
  //     searchValue: null,
  //     sex: "0",
  //     status: "0",
  //     subSystem: 0,
  //     updateBy: null,
  //     updateTime: null,
  //     userId: 0,
  //     userName: "",
  //     userType: "",
  //     userTypeGroup: null,
  // }
 aad(visible : boolean):void{
   console.log(this.tipArr.length,visible,new Date())
   this.tipsShowVisible = visible
  //  if(this.keyword == null || this.keyword == '' || this.keyword.toString().trim().length < 3){
  //    return;
  //  }
  //  this.keywordToInput(this.keyword);
 }
  getUserInfo(): void{
    if(!window.localStorage.getItem("Authorization")){
      return ;
    }
    getUserInfo({}).then(res => {
      if(res.status == 200){
          if(res.data.code == 200){
            this.userInfo = res.data.user
            this.salerQq = res.data.customer.salerQq?res.data.customer.salerQq:"2355713023"
            window.localStorage.setItem("salerQq",this.salerQq);
            window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            window.localStorage.setItem("hxwStatus",JSON.stringify(res.data.customer.hxwStatus));

          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  logout(): void{
     hxwLogout().then(res => {
      
      window.localStorage.clear();
      window.localStorage.removeItem("Authorization");
      this.$message.success("退出成功");
      this.$router.push({ name: 'Login' });
    }).catch(() => {
      console.log(' error ')
    })
  };
  


  listPartNumberTips(): void{
    //onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')"
    // console.log(this.keyword,new Date(),"====3")

    this.tipsShowVisible = true
    this.keyword = this.keyword.toString().trim().replace(/[\u4e00-\u9fa5]/ig,'')
    // console.log(this.keyword,new Date(),"====3")

    if(this.keyword == null || this.keyword.trim() == ''){
      this.tipArr = [];
      return;
    }
    // this.tipArr = [];
    // console.log(this.tipArr,"====1")
    listPartNumberTips(encodeURIComponent(this.keyword)).then(res => {
      // console.log(res)
      if(res.status == 200){
          if(res.data.code == 200){
            // this.tipArr = [];
          //  console.log(res.data.data)
           this.tipArr = res.data.data;
          //  this.showFooter=true;
          // console.log(this.tipArr,"====2")

          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  delayTips():void {
    if (this.timer) {
      clearTimeout(this.timer) // 每次进来的时候都将之前的清除掉，如果还没到一秒的时候就将之前的清除掉，这样就不会触发之前setTimeout绑定的事件， 如果超过一秒，之前的事件就会被触发下次进来的时候同样清除之前的timer
    }
    this.timer = setTimeout(() => {
        // 在这里进行我们的操作  这样就不会频繁的进行我们这里面的操作了
        this.listPartNumberTips()
            // console.log(this.keyword,new Date(),"====5")

    }, 300)
    // console.log(this.keyword,new Date(),"====4")

}
  mounted(): void{
    this.getUserInfo();
    this.keyword = ''
    // console.log(this.$route.name,"11123")
    if (this.$route.name === 'Search') {
      // console.log(this.$route.query.keyword.toString().trim(),"eeqw1")
      this.routeKeyword = this.$route.query.keyword.toString().trim()
      this.keyword = this.routeKeyword
    };
  }; 
  keywordToInput(o :string) :void{
    console.log("keywordToInput被调用")
    console.log(o,this.keyword,"=============")
    
    this.keyword = o;
    this.goSearch();
    this.tipsShowVisible =false
  };
  goSearch(): void {
    this.keyword = this.keyword.replace(/[\u4e00-\u9fa5]/ig,'')

    if(this.keyword.length <= 2){
      this.$message.warning("最少需要输入三位");
      return;
    }
    this.tipsShowVisible = false;
    this.routeKeyword = this.keyword
    this.$router.push({ path: `/search/${new Date().getTime().toString()}?keyword=${ encodeURIComponent(this.keyword )}` })
    // if (this.keyword !== this.routeKeyword) {
    //   this.routeKeyword = this.keyword
    //   this.$router.push({ path: `/search/${this.keyword}` })
    // }
  }
  

}
