














































import { Component, Prop, Vue } from "vue-property-decorator";
import {  listCallOaCategory ,prefixRouter } from "@/api";
@Component
export default class Category extends Vue {
  @Prop() private msg!: string;

  prefixRouter = prefixRouter;
  categoryList =[{
    id:"",
    name:"",
    subcat:[]
  }]
  mounted(): void {
    // this.getProducts()
    this.listCallOaCategory();
   
  }; 

  listCallOaCategory(): void{
    listCallOaCategory({}).then(res => {
      
      if(res.status == 200){
          if(res.data.code == 200){
            
            // console.log(res.data.data)
            this.categoryList = res.data.data
            // console.log(this.categoryList)
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
}
