




























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Category from '@/components/Category.vue'
import HeaderSearch from '@/components/HeaderSearch.vue'
import { getUserInfo, hxwLogout, hxwReg } from "@/api";
import { config } from 'node_modules/vue/types/umd';
@Component({
  components: {
    Category,
    
    HeaderSearch
  },
})
export default class Header extends Vue {
  keyword = '';
  routeKeyword = '';

  get isHome(): boolean {
    return this.$route.name === "Home";
  }

  // @Watch('$route.name')
  // onIdChanged(): void {
  //   if (this.$route.name !== 'Search') {
  //     this.keyword = ''
  //     this.routeKeyword = ''
  //   }
  // }
  isLogin = window.localStorage.getItem('Authorization');
  userInfo = JSON.stringify(window.localStorage.getItem('userInfo'));
  salerQq ="";
  searchKey = "";
  // userInfo = {
  //     admin: false,
  //     avatar: "",
  //     companyId: 0,
  //     companyName: null,
  //     companyType: 2,
  //     createBy: "",
  //     createTime: "",
  //     delFlag: "0",
  //     dept: null,
  //     deptId: null,
  //     email: "",
  //     isVip: 0,
  //     loginDate: null,
  //     loginIp: "",
  //     nickName: "",
  //     orderField: null,
  //     orderSort: null,
  //     params: {},
  //     password: "",
  //     phonenumber: "",
  //     postIds: null,
  //     remark: null,
  //     roleIds: null,
  //     roles: [],
  //     salt: null,
  //     searchValue: null,
  //     sex: "0",
  //     status: "0",
  //     subSystem: 0,
  //     updateBy: null,
  //     updateTime: null,
  //     userId: 0,
  //     userName: "",
  //     userType: "",
  //     userTypeGroup: null,
  // }
  getUserInfo(): void{
    if(!window.localStorage.getItem("Authorization")){
      return ;
    }
    getUserInfo({}).then(res => {
      if(res.status == 200){
          if(res.data.code == 200){
            this.userInfo = res.data.user
            this.salerQq = res.data.customer.salerQq?res.data.customer.salerQq:"2355713023"
            window.localStorage.setItem("salerQq",this.salerQq);
            window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            window.localStorage.setItem("hxwStatus",JSON.stringify(res.data.customer.hxwStatus));

          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  logout(): void{
     hxwLogout().then(res => {
      
      window.localStorage.clear();
      window.localStorage.removeItem("Authorization");
      this.$message.success("退出成功");
      this.$router.push({ name: 'Login' });
    }).catch(() => {
      console.log(' error ')
    })
  };
  mounted(): void{
    this.getUserInfo();
    // this.keyword = ''
    // if (this.$route.name === 'Search') {
    //   // let jsonKey = {}
    //   // jsonKey['keyword'] = this.$route.query.keyword.toString().trim()
    //   // console.log(JSON.stringify(jsonKey))
    //   this.routeKeyword = encodeURIComponent(this.$route.query.keyword.toString().trim())
    //   this.keyword = this.routeKeyword
    // };
  };  
  // goSearch(): void {
  //   if(this.keyword.length <= 2){
  //     this.$message.warning("最少需要输入四位");
  //     return;
  //   }
  //   // this.routeKeyword = this.keyword
  //   // this.$router.push({ path: `/search/${this.keyword}?t=new Date()` })
  //   if (this.keyword !== this.routeKeyword) {
  //     this.routeKeyword = this.keyword
  //     this.$router.push({ path: `/search/${this.keyword}` })
  //   }
  // }
}
